/**
 * @ComponentFor ResetPasswordPageViewModel
 */

import React from 'react';
import { styled } from '@glitz/react';
import { pathCombine, pushState, postJson, translate, URLX } from '@avensia/scope';
import { epiPropertyValue, EpiProperty } from '@avensia/scope-episerver';
import { Part } from 'Shared/PageLayout/index';
import { connectWithFeedback, ConnectPropType } from 'Shared/Button/Feedback';
import { pixelsToUnit } from 'Shared/Style';
import { ErrorMessage } from 'Shared/FeedbackMessage';
import { whitelilac } from 'Shared/Style/colors';
import ResetPasswordPageViewModel from './ResetPasswordPageViewModel.type';
import AccountForm from 'Account/Form/AccountForm';
import PasswordVisibility from 'Shared/PasswordVisibility';
import MainContainer from 'Shared/MainContainer';

type StateType = {
  showPassword?: boolean;
  newPassword?: string;
  changeFailedMessage?: string;
};

type RequiredProps = ResetPasswordPageViewModel;

type ConnectedProps = RequiredProps & ConnectPropType;

export default connectWithFeedback()(
  class ResetPassword extends React.Component<ConnectedProps, StateType> {
    newPasswordElement: any;

    constructor(props: ConnectedProps) {
      super(props);
      this.state = {
        newPassword: '',
        changeFailedMessage: '',
      };
    }

    onSubmit = async () => {
      const urlx = new URLX(window.location.href);
      const token = urlx.searchParams.get('token');
      const email = urlx.searchParams.get('email');
      const newPassword = this.state.newPassword.trim();
      const url = pathCombine(location.pathname, 'UpdatePassword');

      if (!!newPassword) {
        try {
          const { success, message } = await postJson(url, { newPassword, token, email });
          if (success) {
            pushState(epiPropertyValue(this.props.page.pageToRedirectTo).url || '/', { includeAppShellData: true });
          } else {
            this.setState({ changeFailedMessage: message });
            return Promise.reject(null);
          }
        } catch (error) {
          this.setState({ changeFailedMessage: error });
          return Promise.reject(null);
        }
      } else {
        this.setState({ changeFailedMessage: translate('/Account/ResetPassword/InputRequiredField') });
        return Promise.reject(null);
      }
    };

    onPasswordFieldInput = (e: React.FormEvent<HTMLInputElement>) => {
      this.setState({
        newPassword: (e.target as HTMLInputElement).value,
        changeFailedMessage: '',
      });
    };

    render() {
      const isValid = window.IS_IN_EDIT_MODE || this.props.isTokenValid;
      return (
        <MainContainer>
          {isValid ? (
            <AccountForm
              heading={epiPropertyValue(this.props.page.pageTitle)}
              buttonCaption={epiPropertyValue(this.props.page.submitButtonCaption)}
              buttonAction={this.onSubmit}
            >
              <PasswordVisibility
                inputLabel={epiPropertyValue(this.props.page.newPasswordLabel)}
                inputPlaceholder={epiPropertyValue(this.props.page.newPasswordPlaceholder)}
                inputValue={this.state.newPassword}
                onChange={this.onPasswordFieldInput}
                error={this.state.changeFailedMessage}
              />
            </AccountForm>
          ) : (
              <InvalidContentWrapper>
                <EpiProperty for={this.props.page.pageTitle} component="h1" />
                <ErrorMessage>
                  <EpiProperty for={this.props.page.invalidTokenMessage} />
                </ErrorMessage>
              </InvalidContentWrapper>
            )}
        </MainContainer>
      );
    }
  },
);

const InvalidContentWrapper = styled(Part, {
  backgroundColor: whitelilac,
  padding: {
    xy: pixelsToUnit(30),
  },
});
